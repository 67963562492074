import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import TripInspoLogo from "./assets/ti-logo.png";

export function FlightsComponent() {
  var item = {
    img: TripInspoLogo,
    title: "Discover and book amazing travel at exclusive prices",
    link: "TripInspo.com"
  }
  return <>
    <Typography gutterBottom variant="h6" component="div" align="left" style={{ marginTop: 10, marginBottom: 20, textAlign: "center"}}>
      Save Money on Flights
    </Typography>
    <Paper elevation={2} style={{ marginBottom: 40}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{ width: "80%", padding: 5 }}
            />
          </Grid>
          <Grid item xs={6} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
            <Button variant="contained" href={item.link} target="_blank" style={{minWidth: 110, marginRight: 10}}>
              Check Flights
            </Button>
          </Grid>
        </Grid>

        {/* <Divider /> */}
        <Box style={{ display: "flex", justifyContent: "space-between"}}>
          <Typography gutterBottom variant="subtitle2" component="span" align="left" style={{ marginLeft: 10}}>
            {item.title}
          </Typography>
        </Box>
      </Paper>
  </>;
}