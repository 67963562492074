import { Box, Button, Container, Divider, Grid, ImageList, ImageListItem, ImageListItemBar, Paper, Typography } from "@mui/material";
import Dimmer from "./assets/dimmer.png";
import Charger3in1 from "./assets/charger3in1.png";
import StorageContainer from "./assets/storage-container.png";
import Theoloz from "./assets/theoloz.png";

export function LinksComponent() {
  const itemData = [
    {
      img: StorageContainer,
      title: "Fresh Produce Storage Containers Stackable With Vents&Lids, Drain Tray Containers",
      link: "https://amzn.to/3ZD8tMF"
    },
    {
      img: Theoloz,
      title: "Dry Eyes Best Eye Drops Preservative-Free Thealoz Duo Eye Drops 10ml",
      link: "https://amzn.to/3GbXkte"
    },
    {
      img: Dimmer,
      title: "Smart Single Pole Dimmer Switch works with Alexa and Google Home",
      link: "https://amzn.to/3XsYw2m"
    },
    {
      img: Charger3in1,
      title: "Wireless Magnetic Charger Foldable 3 in 1",
      link: "https://amzn.to/3iC75JF"
    }
  ];

  return <>
    {itemData.map((item, i) => (      
      <Paper elevation={2} key={i} style={{ marginBottom: 40}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{ width: "50%", padding: 5 }}
            />
          </Grid>
          <Grid item xs={6} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
            <Button variant="contained" href={item.link} target="_blank" style={{minWidth: 110, marginRight: 10}}>
              See More
            </Button>
          </Grid>
        </Grid>

        {/* <Divider /> */}
        <Box style={{ display: "flex", justifyContent: "space-between"}}>
          <Typography gutterBottom variant="subtitle2" component="span" align="left" style={{ marginLeft: 10}}>
            {item.title}
          </Typography>
        </Box>
      </Paper>
    ))}
  </>
}