import React from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './dashboard';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <Dashboard></Dashboard>
      </div>
    </ThemeProvider>
  );
}

export default App;
